<template>
  <div class="page_three">
    <van-form validate-first @failed="onFailed">
      <van-field label="11.Please select the posture you hold your phone in when you answer the questions above">
        <template #input>
          <van-radio-group v-model="arr3[0]['val']" @change="change">
            <img :src="checkval?h1img:img1" class="img" />
            <van-radio name=" Hold the screen with both hands, swipe with one hand, and select options with the other">
              Hold the screen with both hands, swipe with one hand, and select options with the other
            </van-radio>
            <img :src="checkval?h2img:img2" class="img" />
            <van-radio name="Single hand screen, single hand click option">
              Single hand screen, single hand click option
            </van-radio>
            <img :src="checkval?h3img:img3" class="img" />
            <van-radio name=" The phone is placed on the table, without holding the screen, and the option of tapping independently with one hand">
              The phone is placed on the table, without holding the screen, and the option of tapping independently with one hand
            </van-radio>
            <img :src="checkval?h4img:img4" class="img" />
            <van-radio name="Hold the screen with one hand, the other hand does not hold the screen, and click the option independently">
              Hold the screen with one hand, the other hand does not hold the screen, and click the option independently
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit" @click="submit"
        v-preventReClick
          >next page </van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: "page_three",
  props: ["checkval"],
  data() {
    return {
      // checkImg: "",
      img1: require("@A/img/1.jpg"),
      img2: require("@A/img/2.jpg"),
      img3: require("@A/img/3.jpg"),
      img4: require("@A/img/4.jpg"),
      h1img:require("@A/img/h1.jpg"),
      h2img:require("@A/img/h2.jpg"),
      h3img:require("@A/img/h3.jpg"),
      h4img:require("@A/img/h4.jpg"),
      arr3: [
        {
          id: 0,
          ID:"86",
          title: "11.Please select the posture you hold your phone in when you answer the questions above",
          val: "",
          starTime:"0",
          overTime:"0",
          time:"0"
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0)
    var scrollableElement = document.getElementsByClassName('check')[0];
    scrollableElement.scrollTop = 0;
  },
  methods: {
    onFailed() {},
    submit() {
      let objVal3 = {
        arr: this.arr3,
        name: "pagethree",
      };
      this.$emit("next", objVal3);
    },
    // 设置答题选择时间
    change() {
      console.log("--3-=====");
      this.arr3[0].overTime = this.$moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss.SSS")
    },
  },
};
</script>

<style scoped lang="scss">
.page_three {
  margin: 0 auto;
  .img {
    width: 60%;
    text-align: center;
    // height: 200px;
    margin-top: 20px;
  }
  /deep/.van-button{
    width:200px;
    margin: 0 auto;
    text-align: center
  }
}
</style>
