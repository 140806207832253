
import Vue from "vue";
import Router from "vue-router"

Vue.use(Router)

const routes =[
  {
    path:'/',
    name:'check',
    component:() => import ('@C/check.vue'),
    meta:{
      title:"首页"
    }
  },
  {
    path:'/table',
    name:'table',
    component:() => import ('@C/table.vue'),
    meta:{
      title:"数据表"
    }
  },
  {
    path:'/checkE',
    name:'checkE',
    component:() => import ('@C/checkE.vue'),
    meta:{
      title:"Home"
    }
  },
  {
    path:'/tableE',
    name:'tableE',
    component:() => import ('@C/tableE.vue'),
    meta:{
      title:"table"
    }
  }
]
const router = new Router ({
  routes
})


export default router