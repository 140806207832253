<template>
  <div class="page_one">
    <h2>Psychosocial investigation</h2>
    <p>
      Hello! Welcome to participate in this study. There will be a series of questionnaires, please carefully read the instructions for each questionnaire, and then answer according to how well each description fits you. There is no right or wrong answer to the question, your answer is strictly confidential, the survey results are 
      for academic use only, please do not hesitate to answer truthfully.<br />
      <span class="redstyle"
        >When the questionnaire is opened, it will randomly display either horizontal screen or vertical screen. 
        Please fill in the questionnaire according to the opened screen.</span
      >
    </p>
    <van-form validate-first @failed="onFailed" >
      <van-field  name="Gender" label="1.Gender："  :rules="[{ required: true, message: 'Please select your gender' }]">
        <template #input>
          <van-radio-group @change="change(0)" v-model="pageone[0].val" direction="horizontal">
            <van-radio name="Male">Male</van-radio>
            <van-radio name="Female">Female</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        readonly
        clickable
        label="2.Age:"
        :value="pageone[1].val"
        placeholder="Please select your age"
        @click="showPicker = true"
      />
      <van-popup get-container="body"  v-model="showPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @cancel="showPicker = false"
          @onCancel="onCancel"
          @confirm="onConfirm"
        />
      </van-popup>
      <van-field name="Education" label="3.Education：">
        <template #input>
          <van-radio-group @change="change(2)" v-model="pageone[2].val" direction="horizontal">
            <van-radio name="Junior college and below">Junior college and below    </van-radio>
            <van-radio name="Bachelor">Bachelor</van-radio>
            <van-radio name="Postgraduate and above">Postgraduate and above</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="Your identity" label="4.Your identity：">
        <template #input>
          <van-radio-group @change="change(3)" v-model="pageone[3].val" direction="horizontal">
            <van-radio name="Caucasian">Caucasian</van-radio>
            <van-radio name="African diaspora">African diaspora</van-radio>
            <van-radio name="Asian">Asian</van-radio>
            <van-radio name="Latino">Latino</van-radio>
            <van-radio name="Other">Other</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        name="The number of times you have filled out the psychological questionnaire in the past month is"
        label="5.The number of times you have filled out the psychological questionnaire in the past month is："
      >
        <template #input>
          <van-radio-group @change="change(4)" v-model="pageone[4].val" direction="horizontal">
            <van-radio name="0">0</van-radio>
            <van-radio name="1-3 times">1-3 times</van-radio>
            <van-radio name="3-5 times">3-5 times</van-radio>
            <van-radio name="5-10 times">5-10 times</van-radio>
            <van-radio name="More than 10 times">More than 10 times</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="Your daily mobile usage time is" 
      label="6.Your daily mobile usage time is：">
        <template #input>
          <van-radio-group @change="change(5)" v-model="pageone[5].val" direction="horizontal">
            <van-radio name="Within 3 hours">Within 3 hours</van-radio>
            <van-radio name="3-6hours">3-6hours</van-radio>
            <van-radio name="6-9hours">6-9hours</van-radio>
            <van-radio name="9-12hours">9-12hours</van-radio>
            <van-radio name="More than 12 hours"
              >More than 12 hours</van-radio
            >
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="Your hands" 
      label="7.Your hands：">
        <template #input>
          <van-radio-group @change="change(6)" v-model="pageone[6].val" direction="horizontal">
            <van-radio name="Right-hander">Right-hander</van-radio>
            <van-radio name="Left-hander">Left-hander</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit" @click="submit" v-preventReClick
          >next page </van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: "page_one",
  props: ["checkval"],
  data() {
    return {
      value:"",
      showPicker:false,
      columns:['18','19','20','21','22','23','24','25','26','27','28','29','30',
      '31','32','33','34','35','36','37','38','39','40'
    ],
      pageone:[
        {
          ID:"1",
          title:"1.Gender：",
          val:"",
          starTime:"0",
          overTime:"0",
          time:"0"
        },
        {
          ID:"2",
          title:"2.Age:",
          val:"",
          starTime:"0",
          overTime:"0",
          time:"0"
        },
        {
          ID:"3",
          title:"3.Education：",
          val:"",
          starTime:"0",
          overTime:"0",
          time:"0"
        },
        {
          ID:"4",
          title:"4.Your identity:",
          val:"",
          starTime:"0",
          overTime:"0",
          time:"0"
        },
        {
          ID:"5",
          title:"5.The number of times you have filled out the psychological questionnaire in the past month is：",
          val:"",
          starTime:"0",
          overTime:"0",
          time:"0"
        },
        {
          ID:"6",
          title:"6.Your daily mobile usage time is:",
          val:"",
          starTime:"0",
          overTime:"0",
          time:"0"
        },
        {
          ID:"7",
          title:"7.Your hands：",
          val:"",
          starTime:"0",
          overTime:"0",
          time:"0"
        },
      ]
    };
  },
  mounted() {
    window.scrollTo(0, 0)
    var scrollableElement = document.getElementsByClassName('check')[0];
    scrollableElement.scrollTop = 0;
  },

  methods: {
    onFailed() {},
    submit() {
      console.log(this.pageone,'this.pageone')
      let objVal= {
        arr:this.pageone,
        name:"pageone"
      }
      this.$emit("next", objVal);
    },
    change(index,item){
      // 设置答题选择时间
      this.pageone[index].overTime = this.$moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss.SSS")
    },
    // 年龄
    onConfirm(value) {
      // this.value = value;
      this.pageone[1].val = value
      this.pageone[1].overTime = this.$moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss.SSS")
      this.showPicker = false;
    },
    
    onCancel() {
       console.log('取消');
    },
  },
};
</script>

<style scoped lang="scss">
.page_one {
  /deep/.van-radio-group {
    display: flex;
    flex-direction: column;
    .van-radio {
      margin-top: 20px;
      margin-left: 20px;
    }
  }

  /deep/.van-cell {
      display: flex;
      flex-direction: column;
    }

    /deep/.age .van-cell__value {
      border: 1px solid gainsboro;
      margin-top: 10px;
      width: 250px;
      margin-left: 20px;
      .van-field__control {
        height: 35px;
      }
    }
    /deep/.van-button--block {
      width: 200px;
      margin: 0 auto;
    }
  h2 {
    color: #0095ff;
    margin-top: 50px;
  }
  .redstyle {
    color: red;
  }
  p {
    display: inline-block;
    text-align: justify;
    font-size: 16px;
    color: #262626;
    margin-top: 20px;
    line-height: 25px;
    padding-bottom: 30px;
    border-bottom: 1px dashed #ccc;
  }
}
</style>
