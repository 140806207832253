<template>
  <div class="page_four">
    <p>
      Hello, here is the last part of this study. In fact, the above question is not the main purpose of our study. This study mainly examined whether the electronic questionnaire filled out on the mobile phone has any shortcomings from the perspective of the respondents. Please answer the following questions carefully based on your experience just now. Your real 
      feedback will help us in the subsequent design of the electronic questionnaire.
    </p>
    <van-form validate-first @failed="onFailed">
      <van-field v-for="(item, index) in list" :key="index" :label="item.title">
        <template #input>
          <van-radio-group @change="change(index)" v-model="list[index]['val']">
            <van-radio name="Not at all true"> Not at all true </van-radio>

            <van-radio name="Not true"> Not true </van-radio>

            <van-radio name="True"> True </van-radio>

            <van-radio name="Definitely true"> Definitely true </van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <div style="margin: 16px">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          @click="submit"
          v-preventReClick
          :disabled="disable"
          >sumbit</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: "page_four",
  props: ["checkval", "disable"],
  data() {
    return {
      list: [
        {
          ID: "87",
          title:
            "12.When filling out a questionnaire, if some questions are similar in description, then I will not carefully read the statement of each question before response.",
          model: "my1",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          ID: "88",
          title: "13.When filling out questionnaires, I have a tendency to swipe quickly and select answers quickly.",
          model: "my2",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          ID: "89",
          title: "14.Feeling irritable and uncomfortable during the answer",
          model: "my3",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          ID: "90",
          title: "15.Faced with a lot of questionnaire questions, I have a psychological tendency to quickly choose the ",
          model: "my4",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          ID: "91",
          title:
            "16.I would deliberately adjust some of the options to avoid regular responses and thus deliberately ",
          model: "my5",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0)
    var scrollableElement = document.getElementsByClassName('check')[0];
    scrollableElement.scrollTop = 0;
    
  },
  methods: {
    onFailed() {},
    submit() {
      let objVal4 = {
        arr: this.list,
        name: "pagefour",
      };
      console.log(this.list, "this.list");
      this.$emit("next", objVal4);
    },
    // 设置答题选择时间
    change(index) {
      console.log("--4-=====");
      this.list[index].overTime = this.$moment(new Date().getTime()).format(
        "YYYY-MM-DD HH:mm:ss.SSS"
      );
    },
    resizeScreen(val) {
      const _this = this;
      // 利用 CSS3 旋转 对根容器逆时针旋转 90 度
      const detectOrient = function () {
        let width = document.getElementsByClassName('van-dialog').offsetWidth,
          height = document.getElementsByClassName('van-dialog').offsetHeight,
          $wrapper = _this.$refs.view, // 这里是页面最外层元素
          style = "";
        if(val){
            // 横屏
            console.log("横屏1");
          style += "width:" + height + "px;";
          style += "height:" + width + "px;";
          style +=
            "-webkit-transform: rotate(90deg); transform: rotate(90deg);";
          // 注意旋转中点的处理
          style +=
            "-webkit-transform-origin: " +
            width / 2 +
            "px " +
            width / 2 +
            "px;";
          style += "transform-origin: " + width / 2 + "px " + width / 2 + "px;";
        }
        $wrapper.style.cssText = style;
      };
      window.onresize = detectOrient;
      detectOrient();
    },
  },
};
</script>

<style scoped lang="scss">
.page_four {
  p {
    color: #2e2e2e;
    font-size: 14px;
    text-align: justify;
    line-height: 25px;
  }
  /deep/.van-button {
    width: 200px;
    margin: 0 auto;
    text-align: center;
  }
}
</style>
