import Vue from 'vue'
import { Toast } from 'vant';
import App from './App.vue'
import router from "./views/router/index"
import axios from './http'

import Vant from 'vant'
import 'vant/lib/index.css'
import 'vant/lib/icon/local.css'
Vue.use(Vant)
import * as echarts from "echarts";//引入echarts
Vue.prototype.$echarts = echarts//挂载到vue实例，方便全局使用
import dayjs from 'dayjs'
Vue.prototype.$day = dayjs//挂在到vue的原型对象上，这样可在全局中使用

import index from '@B/index.js'//组件配置
Vue.use(index)
Vue.prototype.$Toast = Toast

import * as moment from 'moment'
Vue.prototype.$moment = moment;//赋值使用

import { Dialog } from 'vant';
Vue.prototype.$Dialog = Dialog

// 公共js方法
import common from "@C/common/common.js";
Vue.prototype.$common = common;
// 按钮节流
import {preventReClick} from "@C/common/throttle.js"
Vue.prototype.$preventReClick = preventReClick;


Vue.config.productionTip = false
Vue.prototype.$axios = axios

new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>',
})