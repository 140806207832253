import axios from 'axios'
axios.create({
  baseURL:'',
  timeout:10000,
  headers:{
    'Content-Type':'application/json',
    'X-Custom-Header':'foobar'
  }
})

export default axios