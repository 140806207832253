<template>
  <div class="page_two">
    <van-form validate-first>
      <van-field label="8.Read the statements below and choose the option that suits you best.">
      </van-field>
      <div class="title">
        <span>Not at all true</span>
        <span>Very not true</span>
        <span>Not true</span>
        <span>unsure</span>
        <span>True</span>
        <span>Very true</span>
        <span>Definitely true</span>
      </div>
      <van-field
        v-for="(item, index) in arr.slice(0, 6)"
        :key="item.id"
        :label="item.title"
      >
        <template #input>
          <van-radio-group
            @change="change(item.id, arr)"
            v-model="arr[item.id]['val']"
          >
            <van-radio name="Not at all true"></van-radio>
            <van-radio name="Very not true"></van-radio>
            <van-radio name="Not true"></van-radio>
            <van-radio name="unsure"></van-radio>
            <van-radio name="True"></van-radio>
            <van-radio name="Very true"></van-radio>
            <van-radio name="Definitely true"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>Not at all true</span>
        <span>Very not true</span>
        <span>Not true</span>
        <span>unsure</span>
        <span>True</span>
        <span>Very true</span>
        <span>Definitely true</span>
      </div>

      <van-field
        v-for="(item, index) in arr.slice(6, 12)"
        :key="item.id"
        :label="item.title"
      >
        <template #input>
          <van-radio-group
            @change="change(item.id, arr)"
            v-model="arr[item.id]['val']"
          >
            <van-radio name="Not at all true"></van-radio>
            <van-radio name="Very not true"></van-radio>
            <van-radio name="Not true"></van-radio>
            <van-radio name="unsure"></van-radio>
            <van-radio name="True"></van-radio>
            <van-radio name="Very true"></van-radio>
            <van-radio name="Definitely true"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
    </van-form>
    <!-- 2 -->
    <van-form validate-first>
      <van-field label="9.Read the statements below and choose the option that suits you best.">
      </van-field>
      <div class="title">
        <span>Not at all true</span>
        <span>Not true</span>
        <span>unsure</span>
        <span>True</span>
        <span>Definitely true</span>
      </div>

      <van-field
        v-for="(item1, index1) in arr1.slice(0, 6)"
        :key="item1.id"
        :label="item1.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index1, arr1)"
            v-model="arr1[index1]['val']"
          >
            <van-radio name="Not at all true"></van-radio>
            <van-radio name="Not true"></van-radio>
            <van-radio name="unsure"></van-radio>
            <van-radio name="True"></van-radio>
            <van-radio name="Definitely true"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>Not at all true</span>
        <span>Not true</span>
        <span>unsure</span>
        <span>True</span>
        <span>Definitely true</span>
      </div>
      <van-field
        v-for="(item1, index1) in arr1.slice(6, 12)"
        :key="item1.id"
        :label="item1.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index1 + 6, arr1)"
            v-model="arr1[index1 + 6]['val']"
          >
          <van-radio name="Not at all true"></van-radio>
            <van-radio name="Not true"></van-radio>
            <van-radio name="unsure"></van-radio>
            <van-radio name="True"></van-radio>
            <van-radio name="Definitely true"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>Not at all true</span>
        <span>Not true</span>
        <span>unsure</span>
        <span>True</span>
        <span>Definitely true</span>
      </div>
      <van-field
        v-for="(item1, index1) in arr1.slice(12, 18)"
        :key="item1.id"
        :label="item1.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index1 + 12, arr1)"
            v-model="arr1[index1 + 12]['val']"
          >
            <van-radio name="Not at all true"></van-radio>
            <van-radio name="Not true"></van-radio>
            <van-radio name="unsure"></van-radio>
            <van-radio name="True"></van-radio>
            <van-radio name="Definitely true"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>Not at all true</span>
        <span>Not true</span>
        <span>unsure</span>
        <span>True</span>
        <span>Definitely true</span>
      </div>
      <van-field
        v-for="(item1, index1) in arr1.slice(18, 25)"
        :key="item1.id"
        :label="item1.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index1 + 18, arr1)"
            v-model="arr1[index1 + 18]['val']"
          >
          <van-radio name="Not at all true"></van-radio>
            <van-radio name="Not true"></van-radio>
            <van-radio name="unsure"></van-radio>
            <van-radio name="True"></van-radio>
            <van-radio name="Definitely true"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>Not at all true</span>
        <span>Not true</span>
        <span>unsure</span>
        <span>True</span>
        <span>Definitely true</span>
      </div>
      <van-field
        v-for="(item1, index1) in arr1.slice(25, 31)"
        :key="item1.id"
        :label="item1.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index1 + 25, arr1)"
            v-model="arr1[index1 + 25]['val']"
          >
          <van-radio name="Not at all true"></van-radio>
            <van-radio name="Not true"></van-radio>
            <van-radio name="unsure"></van-radio>
            <van-radio name="True"></van-radio>
            <van-radio name="Definitely true"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>Not at all true</span>
        <span>Not true</span>
        <span>unsure</span>
        <span>True</span>
        <span>Definitely true</span>
      </div>
      <van-field
        v-for="(item1, index1) in arr1.slice(31, 37)"
        :key="item1.id"
        :label="item1.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index1 + 31, arr1)"
            v-model="arr1[index1 + 31]['val']"
          >
          <van-radio name="Not at all true"></van-radio>
            <van-radio name="Not true"></van-radio>
            <van-radio name="unsure"></van-radio>
            <van-radio name="True"></van-radio>
            <van-radio name="Definitely true"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>Not at all true</span>
        <span>Not true</span>
        <span>unsure</span>
        <span>True</span>
        <span>Definitely true</span>
      </div>
      <van-field
        v-for="(item1, index1) in arr1.slice(37, 43)"
        :key="item1.id"
        :label="item1.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index1 + 37, arr1)"
            v-model="arr1[index1 + 37]['val']"
          >
          <van-radio name="Not at all true"></van-radio>
            <van-radio name="Not true"></van-radio>
            <van-radio name="unsure"></van-radio>
            <van-radio name="True"></van-radio>
            <van-radio name="Definitely true"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>Not at all true</span>
        <span>Not true</span>
        <span>unsure</span>
        <span>True</span>
        <span>Definitely true</span>
      </div>
      <van-field
        v-for="(item1, index1) in arr1.slice(43, 46)"
        :key="item1.id"
        :label="item1.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index1 + 43, arr1)"
            v-model="arr1[index1 + 43]['val']"
          >
          <van-radio name="Not at all true"></van-radio>
            <van-radio name="Not true"></van-radio>
            <van-radio name="unsure"></van-radio>
            <van-radio name="True"></van-radio>
            <van-radio name="Definitely true"></van-radio>
          </van-radio-group>
        </template>
      </van-field>


    </van-form>
    <!-- 3 -->
    <van-form validate-first>
      <van-field label="10.Read the statements below and choose the option that suits you best.">
      </van-field>
      <div class="title">
        <span>Strongly disagreed</span>
        <span>Disagreed</span>
        <span>unsure</span>
        <span>agreed</span>
        <span>Strongly agreed</span>
      </div>
      <van-field
        v-for="(item2, index2) in arr2.slice(0, 5)"
        :key="item2.id"
        :label="item2.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index2, arr2)"
            v-model="arr2[index2]['val']"
          >
          <van-radio name="Strongly disagreed"></van-radio>
            <van-radio name="Disagreed"></van-radio>
            <van-radio name="unsure"></van-radio>
            <van-radio name="agreed"></van-radio>
            <van-radio name="Strongly agreed"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>Strongly disagreed</span>
        <span>Disagreed</span>
        <span>unsure</span>
        <span>agreed</span>
        <span>Strongly agreed</span>
      </div>
      <van-field
        v-for="(item2, index2) in arr2.slice(5, 10)"
        :key="item2.id"
        :label="item2.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index2 + 5, arr2)"
            v-model="arr2[index2 + 5]['val']"
          >
          <van-radio name="Strongly disagreed"></van-radio>
            <van-radio name="Disagreed"></van-radio>
            <van-radio name="unsure"></van-radio>
            <van-radio name="agreed"></van-radio>
            <van-radio name="Strongly agreed"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>Strongly disagreed</span>
        <span>Disagreed</span>
        <span>unsure</span>
        <span>agreed</span>
        <span>Strongly agreed</span>
      </div>
      <van-field
        v-for="(item2, index2) in arr2.slice(10, 15)"
        :key="item2.id"
        :label="item2.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index2 + 10, arr2)"
            v-model="arr2[index2 + 10]['val']"
          >
          <van-radio name="Strongly disagreed"></van-radio>
            <van-radio name="Disagreed"></van-radio>
            <van-radio name="unsure"></van-radio>
            <van-radio name="agreed"></van-radio>
            <van-radio name="Strongly agreed"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>Strongly disagreed</span>
        <span>Disagreed</span>
        <span>unsure</span>
        <span>agreed</span>
        <span>Strongly agreed</span>
      </div>
      <van-field
        v-for="(item2, index2) in arr2.slice(15, 20)"
        :key="item2.id"
        :label="item2.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index2 + 15, arr2)"
            v-model="arr2[index2 + 15]['val']"
          >
          <van-radio name="Strongly disagreed"></van-radio>
            <van-radio name="Disagreed"></van-radio>
            <van-radio name="unsure"></van-radio>
            <van-radio name="agreed"></van-radio>
            <van-radio name="Strongly agreed"></van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit" @click="submit"
        v-preventReClick
          >next page </van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: "page_two",
  props: ["checkval"],
  data() {
    return {
      arr: [
        {
          id: 0,
          ID:"8",
          title: "Everything in the universe is somehow related to each other",
          model: "val1",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 1,
          ID:"9",
          title: "Even a small change in any element of the universe can lead to significant alterations in other elements",
          model: "val2",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 2,
          ID:"10",
          title: "Any phenomenon has numerous numbers of causes, although some of the causes are not known",
          model: "val3",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 3,
          ID:"11",
          title: "It is more desirable to take the middle ground than go to extremes",
          model: "val4",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 4,
          ID:"12",
          title: "It is more important to find a point of compromise than to debate who is right/wrong, when one's opinions conflict with other's opinions.",
          model: "val5",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 5,
          ID:"13",
          title: "We should avoid going to extremes",
          model: "val6",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 6,
          ID:"14",
          title: "Every phenomenon in the world moves in predictable directions",
          model: "val7",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 7,
          ID:"15",
          title: "A person who is currently living a successful life will continue to stay successful",
          model: "val8",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 8,
          ID:"16",
          title: "Future events are predictable based on present situations",
          model: "val9",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 9,
          ID:"17",
          title: "The whole, rather than its parts, should be considered in order to understand a phenomenon",
          model: "val10",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 10,
          ID:"18",
          title: "It is more important to pay attention to the whole than its parts",
          model: "val11",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 11,
          ID:"19",
          title: "It is more important to pay attention to the whole context rather than the details",
          model: "val12",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
      ],
      arr1: [    
        {
          id: 1,
          ID:"20",
          title: "I often find myself performing tasks that I had intended to do days before.",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 2,
          ID:"21",
          title: "I won't do my homework until the deadline.",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 3,
          ID:"22",
          title: "I will return the borrowed books as soon as they are not due.",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 4,
          ID:"23",
          title: "When it is time to get up in the morning, I most often get right out of bed.",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 5,
          ID:"24",
          title: "Sometimes it takes me days to get around to responding to my (e)mail",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 6,
          ID:"25",
          title: "I usually return a R.S.V.P. request very shortly after receiving it.",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 7,
          ID:"26",
          title: "I rarely finish even very simple, easy tasks in a few days.",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 8,
          ID:"27",
          title: "I usually make decisions as soon as possible.",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 9,
          ID:"28",
          title: "I generally delay before starting work I have to do",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 10,
          ID:"29",
          title: "I usually rush to finish my homework on time.",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 11,
          ID:"30",
          title: "The sun rises in the east",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 12,
          ID:"31",
          title: "When preparing to go out, I am seldom caught having to do something at the last minute.",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 13,
          ID:"32",
          title: "In preparing for some deadlines, I often waste time by doing other things.",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 14,
          ID:"33",
          title: "When planning a party, I make the necessary arrangements well in advance.",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 15,
          ID:"34",
          title: "I usually start doing my homework soon after it is assigned",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 16,
          ID:"35",
          title: "I often have a task finished sooner than necessary.",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 17,
          ID:"36",
          title: "I always seem to end up shopping for birthday gifts at the last minute.",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 18,
          ID:"37",
          title: "I usually buy even an essential item at the last minute.",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 19,
          ID:"38",
          title: "I usually accomplish all the things I plan to do in a day",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 20,
          ID:"39",
          title: "I am continually saying I’ll do it tomorrow. ",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 21,
          ID:"40",
          title: "I usually take care of all the tasks I have to do before I settle down and relax for the evening.",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },

        {
          id: 22,
          ID:"41",
          title: "I express my feelings openly.",
          model: "tim1",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 23,
          ID:"42",
          title: "Sometimes other people think one thing is bad, but I do not think it is bad.",
          model: "tim2",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 24,
          ID:"43",
          title: "I care about how well I do at school or work.",
          model: "tim3",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 25,
          ID:"44",
          title: "I do not care who I hurt to get what I want.",
          model: "tim4",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 26,
          ID:"45",
          title: "I feel bad or guilty when I do something wrong",
          model: "tim5",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 27,
          ID:"46",
          title: "I am concerned about the feelings of others.",
          model: "tim6",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 28,
          ID:"47",
          title: "I do not care about being on time.",
          model: "tim7",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 29,
          ID:"48",
          title: "I am susceptible to the feelings of others",
          model: "tim8",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 30,
          ID:"49",
          title: "I do not care if I get into trouble.",
          model: "tim9",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 31,
          ID:"50",
          title: "I do not allow myself to be soft",
          model: "tim10",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 32,
          ID:"51",
          title: "I do not care about doing things well.",
          model: "tim11",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 33,
          ID:"52",
          title: "I seem very cold and uncaring to others.",
          model: "tim12",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 34,
          ID:"53",
          title: "I easily admit to being wrong.",
          model: "tim13",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 35,
          ID:"54",
          title: "I am very expressive and emotional.",
          model: "tim14",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 36,
          ID:"55",
          title: "I always try my best.",
          model: "tim15",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 37,
          ID:"56",
          title: "I apologize ('say I am sorry:) to persons I hurt.",
          model: "tim16",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 38,
          ID:"57",
          title: "I try not to hurt other's feelings",
          model: "tim17",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 39,
          ID:"58",
          title: "I do not feel remorseful when I do something wrong.",
          model: "tim18",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 40,
          ID:"59",
          title: "I am very expressive and emotional.",
          model: "tim19",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 41,
          ID:"60",
          title: "I do not like to put the time into doing things well.",
          model: "tim20",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 42,
          ID:"61",
          title: "The feelings of others are unimportant to me.",
          model: "tim21",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 43,
          ID:"62",
          title: "I have never lived on Venus.",
          model: "tim22",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 44,
          ID:"63",
          title: "I hide my feelings from others",
          model: "tim23",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 45,
          ID:"64",
          title: "I work hard on everything I do.",
          model: "tim24",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 46,
          ID:"65",
          title: "I do things to make others feel good.",
          model: "tim25",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
      ],
      arr2: [
        {
          id: "00",
          ID:"66",
          title: "It’s no big deal to skip a few classes",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",

          model: "kim1",
        },
        {
          id: "01",
          ID:"67",
          title: "I don’t like having too bey all the rules at home and school",
          model: "kim2",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "02",
          ID:"68",
          title: "I'd feel pretty bad if I broke the rules at my school ",
          model: "kim3",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "03",
          ID:"69",
          title: "Parents should know when their kids hang around with “bad” friends",
          model: "kim4",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "04",
          ID:"70",
          title: "Kid's shouldn't talk back to the teacher",
          model: "kim5",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "05",
          ID:"71",
          title: "It's not right to yell at your father ",
          model: "kim6",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "06",
          ID:"72",
          title: "A lot of teachers bother kids too much",
          model: "kim7",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "07",
          ID:"73",
          title: "It's none of parents’ business what kids do after school",
          model: "kim8",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "08",
          ID:"74",
          title: "I'm afraid to hang around kids who get into trouble",
          model: "kim9",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "09",
          ID:"75",
          title: "I respect kids who listen to their parents ",
          model: "kim10",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "010",
          ID:"76",
          title: "Fighting is cool when you're with a group of kids",
          model: "kim11",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "011",
          ID:"77",
          title: "It's OK to walk away from a fight",
          model: "kim12",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "012",
          ID:"78",
          title: "You have to hurt the other kid before he hurts you",
          model: "kim13",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "013",
          ID:"79",
          title: "Some kids deserve to be picked on",
          model: "kim14",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "014",
          ID:"80",
          title: "Blaming other kids is a good way to avoid getting in trouble",
          model: "kim15",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "015",
          ID:"81",
          title: "Fighting is wrong, even when somebody is really bothering you",
          model: "kim16",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "016",
          ID:"82",
          title: "It's fun and exciting to belong to a gang",
          model: "kim17",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "017",
          ID:"83",
          title: "Kid's feel better when they can win a fight",
          model: "kim18",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "018",
          ID:"84",
          title: "Being in a gang stops you from getting picked on",
          model: "kim19",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "019",
          ID:"85",
          title: "I have answered all the above questions carefully",
          model: "kim20",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
      ],
    };
  },
  mounted() {
    console.log("222", this.checkval);
    window.scrollTo(0, 0);
    var scrollableElement = document.getElementsByClassName('check')[0];
    scrollableElement.scrollTop = 0;
  },
  methods: {
    onFailed() {},
    submit() {
      console.log(this.arr, this.arr1, this.arr2, "----");
      let objVal2 = {
        arr: this.arr,
        arr1: this.arr1,
        arr2: this.arr2,
        name: "pagetwo",
      };
      this.$emit("next", objVal2);
    },
    // 设置答题选择时间
    change(index, val) {
      console.log(index, val, "--2--=====");
      val[index].overTime = this.$moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss.SSS")
    },
  },
};
</script>

<style scoped lang="scss">
.page_two {
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: rgb(244, 244, 244);
    padding: 10px 5px;
    box-sizing: border-box;
  }
  span {
    width: 100%;
    font-size: 13px;
    font-weight: bold;
    color: rgb(38, 38, 38);
  }
  /deep/.van-button{
    width:200px;
    margin: 0 auto;
    text-align: center
  }
}
</style>
