<template>
  <div class="page_two">
    <van-form validate-first>
      <van-field label="8.请阅读下面的表述，在选择最符合自己的选项。">
      </van-field>
      <div class="title">
        <span>极不符合</span>
        <span>非常不符合</span>
        <span>不符合</span>
        <span>不确定</span>
        <span>符合</span>
        <span>非常符合</span>
        <span>极符合</span>
      </div>
      <van-field
        v-for="(item, index) in arr.slice(0, 6)"
        :key="item.id"
        :label="item.title"
      >
        <template #input>
          <van-radio-group
            @change="change(item.id, arr)"
            v-model="arr[item.id]['val']"
          >
            <van-radio name="极不符合"></van-radio>
            <van-radio name="非常不符合"></van-radio>
            <van-radio name="不符合"></van-radio>
            <van-radio name="不确定"></van-radio>
            <van-radio name="符合"></van-radio>
            <van-radio name="非常符合"></van-radio>
            <van-radio name="极符合"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>极不符合</span>
        <span>非常不符合</span>
        <span>不符合</span>
        <span>不确定</span>
        <span>符合</span>
        <span>非常符合</span>
        <span>极符合</span>
      </div>

      <van-field
        v-for="(item, index) in arr.slice(6, 12)"
        :key="item.id"
        :label="item.title"
      >
        <template #input>
          <van-radio-group
            @change="change(item.id, arr)"
            v-model="arr[item.id]['val']"
          >
            <van-radio name="极不符合"> </van-radio>
            <van-radio name="非常不符合"></van-radio>
            <van-radio name="不符合"></van-radio>
            <van-radio name="不确定"></van-radio>
            <van-radio name="符合"></van-radio>
            <van-radio name="非常符合"></van-radio>
            <van-radio name="极符合"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
    </van-form>
    <!-- 2 -->
    <van-form validate-first>
      <van-field label="9.请阅读下面的表述，在选择最符合自己的选项。">
      </van-field>
      <div class="title">
        <span>非常不符合</span>
        <span>不符合</span>
        <span>不确定</span>
        <span>符合</span>
        <span>非常符合</span>
      </div>

      <van-field
        v-for="(item1, index1) in arr1.slice(0, 6)"
        :key="item1.id"
        :label="item1.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index1, arr1)"
            v-model="arr1[index1]['val']"
          >
            <van-radio name="非常不符合"></van-radio>
            <van-radio name="不符合"></van-radio>
            <van-radio name="不确定"></van-radio>
            <van-radio name="符合"></van-radio>
            <van-radio name="非常符合"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>非常不符合</span>
        <span>不符合</span>
        <span>不确定</span>
        <span>符合</span>
        <span>非常符合</span>
      </div>
      <van-field
        v-for="(item1, index1) in arr1.slice(6, 12)"
        :key="item1.id"
        :label="item1.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index1 + 6, arr1)"
            v-model="arr1[index1 + 6]['val']"
          >
            <van-radio name="非常不符合"></van-radio>
            <van-radio name="不符合"></van-radio>
            <van-radio name="不确定"></van-radio>
            <van-radio name="符合"></van-radio>
            <van-radio name="非常符合"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>非常不符合</span>
        <span>不符合</span>
        <span>不确定</span>
        <span>符合</span>
        <span>非常符合</span>
      </div>
      <van-field
        v-for="(item1, index1) in arr1.slice(12, 18)"
        :key="item1.id"
        :label="item1.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index1 + 12, arr1)"
            v-model="arr1[index1 + 12]['val']"
          >
            <van-radio name="非常不符合"></van-radio>
            <van-radio name="不符合"></van-radio>
            <van-radio name="不确定"></van-radio>
            <van-radio name="符合"></van-radio>
            <van-radio name="非常符合"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>非常不符合</span>
        <span>不符合</span>
        <span>不确定</span>
        <span>符合</span>
        <span>非常符合</span>
      </div>
      <van-field
        v-for="(item1, index1) in arr1.slice(18, 25)"
        :key="item1.id"
        :label="item1.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index1 + 18, arr1)"
            v-model="arr1[index1 + 18]['val']"
          >
            <van-radio name="非常不符合"></van-radio>
            <van-radio name="不符合"></van-radio>
            <van-radio name="不确定"></van-radio>
            <van-radio name="符合"></van-radio>
            <van-radio name="非常符合"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>非常不符合</span>
        <span>不符合</span>
        <span>不确定</span>
        <span>符合</span>
        <span>非常符合</span>
      </div>
      <van-field
        v-for="(item1, index1) in arr1.slice(25, 31)"
        :key="item1.id"
        :label="item1.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index1 + 25, arr1)"
            v-model="arr1[index1 + 25]['val']"
          >
            <van-radio name="非常不符合"></van-radio>
            <van-radio name="不符合"></van-radio>
            <van-radio name="不确定"></van-radio>
            <van-radio name="符合"></van-radio>
            <van-radio name="非常符合"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>非常不符合</span>
        <span>不符合</span>
        <span>不确定</span>
        <span>符合</span>
        <span>非常符合</span>
      </div>
      <van-field
        v-for="(item1, index1) in arr1.slice(31, 37)"
        :key="item1.id"
        :label="item1.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index1 + 31, arr1)"
            v-model="arr1[index1 + 31]['val']"
          >
            <van-radio name="非常不符合"></van-radio>
            <van-radio name="不符合"></van-radio>
            <van-radio name="不确定"></van-radio>
            <van-radio name="符合"></van-radio>
            <van-radio name="非常符合"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>非常不符合</span>
        <span>不符合</span>
        <span>不确定</span>
        <span>符合</span>
        <span>非常符合</span>
      </div>
      <van-field
        v-for="(item1, index1) in arr1.slice(37, 43)"
        :key="item1.id"
        :label="item1.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index1 + 37, arr1)"
            v-model="arr1[index1 + 37]['val']"
          >
            <van-radio name="非常不符合"></van-radio>
            <van-radio name="不符合"></van-radio>
            <van-radio name="不确定"></van-radio>
            <van-radio name="符合"></van-radio>
            <van-radio name="非常符合"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>非常不符合</span>
        <span>不符合</span>
        <span>不确定</span>
        <span>符合</span>
        <span>非常符合</span>
      </div>
      <van-field
        v-for="(item1, index1) in arr1.slice(43, 46)"
        :key="item1.id"
        :label="item1.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index1 + 43, arr1)"
            v-model="arr1[index1 + 43]['val']"
          >
            <van-radio name="非常不符合"></van-radio>
            <van-radio name="不符合"></van-radio>
            <van-radio name="不确定"></van-radio>
            <van-radio name="符合"></van-radio>
            <van-radio name="非常符合"></van-radio>
          </van-radio-group>
        </template>
      </van-field>


    </van-form>
    <!-- 3 -->
    <van-form validate-first>
      <van-field label="10.请阅读下面的表述，在选择最符合自己的选项。">
      </van-field>
      <div class="title">
        <span>非常不赞同</span>
        <span>不赞同</span>
        <span>不确定</span>
        <span>赞同</span>
        <span>非常赞同</span>
      </div>
      <van-field
        v-for="(item2, index2) in arr2.slice(0, 5)"
        :key="item2.id"
        :label="item2.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index2, arr2)"
            v-model="arr2[index2]['val']"
          >
          <van-radio name="非常不赞同"></van-radio>
            <van-radio name="不赞同"></van-radio>
            <van-radio name="不确定"></van-radio>
            <van-radio name="赞同"></van-radio>
            <van-radio name="非常赞同"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>非常不赞同</span>
        <span>不赞同</span>
        <span>不确定</span>
        <span>赞同</span>
        <span>非常赞同</span>
      </div>
      <van-field
        v-for="(item2, index2) in arr2.slice(5, 10)"
        :key="item2.id"
        :label="item2.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index2 + 5, arr2)"
            v-model="arr2[index2 + 5]['val']"
          >
          <van-radio name="非常不赞同"></van-radio>
            <van-radio name="不赞同"></van-radio>
            <van-radio name="不确定"></van-radio>
            <van-radio name="赞同"></van-radio>
            <van-radio name="非常赞同"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>非常不赞同</span>
        <span>不赞同</span>
        <span>不确定</span>
        <span>赞同</span>
        <span>非常赞同</span>
      </div>
      <van-field
        v-for="(item2, index2) in arr2.slice(10, 15)"
        :key="item2.id"
        :label="item2.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index2 + 10, arr2)"
            v-model="arr2[index2 + 10]['val']"
          >
          <van-radio name="非常不赞同"></van-radio>
            <van-radio name="不赞同"></van-radio>
            <van-radio name="不确定"></van-radio>
            <van-radio name="赞同"></van-radio>
            <van-radio name="非常赞同"></van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="title">
        <span>非常不赞同</span>
        <span>不赞同</span>
        <span>不确定</span>
        <span>赞同</span>
        <span>非常赞同</span>
      </div>
      <van-field
        v-for="(item2, index2) in arr2.slice(15, 20)"
        :key="item2.id"
        :label="item2.title"
      >
        <template #input>
          <van-radio-group
            @change="change(index2 + 15, arr2)"
            v-model="arr2[index2 + 15]['val']"
          >
            <van-radio name="非常不赞同"></van-radio>
            <van-radio name="不赞同"></van-radio>
            <van-radio name="不确定"></van-radio>
            <van-radio name="赞同"></van-radio>
            <van-radio name="非常赞同"></van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit" @click="submit"
        v-preventReClick
          >下一页</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: "page_two",
  props: ["checkval"],
  data() {
    return {
      arr: [
        {
          id: 0,
          ID:"8",
          title: "宇宙万物之间都存在某种程度的联系",
          model: "val1",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 1,
          ID:"9",
          title: "宇宙中任何元素的微小变化都会对其他元素产生显著的影响。",
          model: "val2",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 2,
          ID:"10",
          title: "任何现象均具备多种潜在原因，尽管其中某些原因尚不明确。",
          model: "val3",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 3,
          ID:"11",
          title: "与其陷入极端，中庸之道更为可取。",
          model: "val4",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 4,
          ID:"12",
          title: "当双方观点发生冲突时，寻求一个折中点比争论谁对谁错更重要",
          model: "val5",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 5,
          ID:"13",
          title: "为人处世应避免走极端",
          model: "val6",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 6,
          ID:"14",
          title: "世上的每种现象的发展方向是不可预测的",
          model: "val7",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 7,
          ID:"15",
          title: "目前正过着成功生活的人并不一定会继续保持成功",
          model: "val8",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 8,
          ID:"16",
          title: "未来事件并不一定会可根据现状被预测",
          model: "val9",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 9,
          ID:"17",
          title: "理解一种现象，应该考虑整体而不是局部",
          model: "val10",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 10,
          ID:"18",
          title: "关注整体比关注局部更重要",
          model: "val11",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 11,
          ID:"19",
          title: "关注整体情境比关注琐碎细节更重要",
          model: "val12",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
      ],
      arr1: [    
        {
          id: 1,
          ID:"20",
          title: "我经常发现自己在做几天前就已经打算要做的事情。",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 2,
          ID:"21",
          title: "临交差时我才会去做作业。",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 3,
          ID:"22",
          title: "读完借阅的书籍后，不管到不到期，我都会立即归还。",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 4,
          ID:"23",
          title: "早晨到起床时间，我总是马上就起。",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 5,
          ID:"24",
          title: "信写完后，我可能会放几天才寄出。",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 6,
          ID:"25",
          title: "我总是迅速回电话",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 7,
          ID:"26",
          title: "即使是非常简单、容易的工作，我也很少会在几天之内做完。",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 8,
          ID:"27",
          title: "我通常迅速做出决定，",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 9,
          ID:"28",
          title: "我总是推迟必须要做的工作",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 10,
          ID:"29",
          title: "我通常匆匆忙忙赶着做，才能按时完成作业。",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 11,
          ID:"30",
          title: "太阳是从东边升起",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 12,
          ID:"31",
          title: "临出门时，我常会在出发前发现还有一些事情没有准备",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 13,
          ID:"32",
          title: "在任务完成的最后期限，我经常浪费时间去做其他事情。",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 14,
          ID:"33",
          title: "去赴约我一般都会提前出发",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 15,
          ID:"34",
          title: "我通常在作业布置之后很快开始做它",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 16,
          ID:"35",
          title: "我通常提前完成任务。",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 17,
          ID:"36",
          title: "我似乎总是到最后一刻才去选购生日或节日礼物",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 18,
          ID:"37",
          title: "即使是必须品，我也通常拖到最后一刻才去买",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 19,
          ID:"38",
          title: "我通常会一天内完成计划好的所有事情",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 20,
          ID:"39",
          title: "我经常说:“明天再做”",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 21,
          ID:"40",
          title: "在晚上休息之前，我通常会处理好必须完成的所有任务",
      
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },

        {
          id: 22,
          ID:"41",
          title: "我不加掩饰地表露我的情绪",
          model: "tim1",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 23,
          ID:"42",
          title: "有时候虽然其他人认为是做了坏事，但我并不觉得有什么",
          model: "tim2",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 24,
          ID:"43",
          title: "我很在意自己在学校或工作中的表现是否出色",
          model: "tim3",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 25,
          ID:"44",
          title: "为了得到我想要的，我不在意会伤害到谁.",
          model: "tim4",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 26,
          ID:"45",
          title: "只要做错了事我就会感到难过或自责",
          model: "tim5",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 27,
          ID:"46",
          title: "我不向别人显露我的情绪",
          model: "tim6",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 28,
          ID:"47",
          title: "我不在意我是否准时",
          model: "tim7",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 29,
          ID:"48",
          title: "我容易受到其他人情绪的影响",
          model: "tim8",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 30,
          ID:"49",
          title: "我不担心是否我会惹来麻烦",
          model: "tim9",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 31,
          ID:"50",
          title: "我不允许自己心软",
          model: "tim10",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 32,
          ID:"51",
          title: "我不在意事情做得好不好",
          model: "tim11",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 33,
          ID:"52",
          title: "我比较冷淡，很少怜悯其他人",
          model: "tim12",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 34,
          ID:"53",
          title: "错了我会承认",
          model: "tim13",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 35,
          ID:"54",
          title: "我把自己的喜怒哀乐都挂在脸上，所以其他人都会知道我的情绪状态",
          model: "tim14",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 36,
          ID:"55",
          title: "我总是尽最大努力(把事情做好)",
          model: "tim15",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 37,
          ID:"56",
          title: "我会向我伤害了的人道歉",
          model: "tim16",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 38,
          ID:"57",
          title: "我尽可能不伤害到其他人的感情",
          model: "tim17",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 39,
          ID:"58",
          title: "我不会为做错了事而懊悔",
          model: "tim18",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 40,
          ID:"59",
          title: "我很善于表达我(丰富)的情绪",
          model: "tim19",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 41,
          ID:"60",
          title: "我不会因为要把事情做好而多花时间",
          model: "tim20",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 42,
          ID:"61",
          title: "别人的感受对我来说无关紧要",
          model: "tim21",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 43,
          ID:"62",
          title: "我没有去过金星居住",
          model: "tim22",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 44,
          ID:"63",
          title: "我对其他人隐藏我的情绪",
          model: "tim23",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 45,
          ID:"64",
          title: "做每一件事我都努力(做好)",
          model: "tim24",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: 46,
          ID:"65",
          title: "我会为了让其他人心情舒畅而付出努力",
          model: "tim25",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
      ],
      arr2: [
        {
          id: "00",
          ID:"66",
          title: "翘几节课没什么大不了的",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",

          model: "kim1",
        },
        {
          id: "01",
          ID:"67",
          title: "我不喜欢在家里和学校必须遵守所有的规则",
          model: "kim2",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "02",
          ID:"68",
          title: "如果我违反了学校的规定，我会感觉很糟糕",
          model: "kim3",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "03",
          ID:"69",
          title: "父母应该知道孩子什么时候和不良朋友在一起",
          model: "kim4",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "04",
          ID:"70",
          title: "学生不应该和老师顶嘴",
          model: "kim5",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "05",
          ID:"71",
          title: "对父亲大吼大叫是不对的",
          model: "kim6",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "06",
          ID:"72",
          title: "很多老师太打扰学生了",
          model: "kim7",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "07",
          ID:"73",
          title: "学生放学后做什么不关父母的事",
          model: "kim8",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "08",
          ID:"74",
          title: "我害怕和惹麻烦的学生在一起",
          model: "kim9",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "09",
          ID:"75",
          title: "我尊重听父母话的同学",
          model: "kim10",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "010",
          ID:"76",
          title: "当你和一群同学在一起打架的时候很酷",
          model: "kim11",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "011",
          ID:"77",
          title: "从一场斗殴中走开是可以的",
          model: "kim12",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "012",
          ID:"78",
          title: "我必须在另一个人伤害我之前伤害他",
          model: "kim13",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "013",
          ID:"79",
          title: "有些人值得被欺负",
          model: "kim14",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "014",
          ID:"80",
          title: "责备其他同学是避免惹麻烦的好方法",
          model: "kim15",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "015",
          ID:"81",
          title: "吵架是不对的，即使有人真的在烦你",
          model: "kim16",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "016",
          ID:"82",
          title: "加入一个团伙很有趣也很刺激",
          model: "kim17",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "017",
          ID:"83",
          title: "当同学们在打架中获胜，他们会感觉更好",
          model: "kim18",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "018",
          ID:"84",
          title: "加入团伙能防止你被人欺负",
          model: "kim19",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          id: "019",
          ID:"85",
          title: "以上问题我都认真作答",
          model: "kim20",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
      ],
    };
  },
  mounted() {
    console.log("222", this.checkval);
    window.scrollTo(0, 0);
    var scrollableElement = document.getElementsByClassName('check')[0];
    scrollableElement.scrollTop = 0;
  },
  methods: {
    onFailed() {},
    submit() {
      console.log(this.arr, this.arr1, this.arr2, "----");
      let objVal2 = {
        arr: this.arr,
        arr1: this.arr1,
        arr2: this.arr2,
        name: "pagetwo",
      };
      this.$emit("next", objVal2);
    },
    // 设置答题选择时间
    change(index, val) {
      console.log(index, val, "--2--=====");
      val[index].overTime = this.$moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss.SSS")
    },
  },
};
</script>

<style scoped lang="scss">
.page_two {
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: rgb(244, 244, 244);
    padding: 10px 5px;
    box-sizing: border-box;
  }
  span {
    width: 100%;
    font-size: 13px;
    font-weight: bold;
    color: rgb(38, 38, 38);
  }
  /deep/.van-button{
    width:200px;
    margin: 0 auto;
    text-align: center
  }
}
</style>
