<template>
  <div class="page_one">
    <h2>社会心理调查</h2>
    <p>
      你好！欢迎参加本研究。接下来会有一系列调查问卷，请你先认真阅读每份问卷的指导语，
      然后根据每个描述与你的符合程度做出回答。题目的答案没有对错之分，
      你的回答是绝对保密的，调查结果仅做学术使用，请不必顾虑，如实回答。<br />
      <span class="redstyle"
        >问卷打开会随机呈现横屏或竖屏两种情况，请你按照打开的情况填写即可。</span
      >
    </p>
    <van-form validate-first @failed="onFailed" >
      <van-field  name="性别" label="1.你的性别："  :rules="[{ required: true, message: '请选择你的性别' }]">
        <template #input>
          <van-radio-group @change="change(0)" v-model="pageone[0].val" direction="horizontal">
            <van-radio name="男">男</van-radio>
            <van-radio name="女">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <!-- <van-field
        v-model="pageone[1].val"
        type="number"
        name="年龄"
        label="2.年龄:"
        class="age"
        @change="change(1)"
        :rules="[{ required: true, message: '年龄' }]"
      /> -->
      <van-field
        readonly
        clickable
        label="2.年龄:"
        :value="pageone[1].val"
        placeholder="选择年龄"
        @click="showPicker = true"
      />
      <van-popup get-container="body"  v-model="showPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @cancel="showPicker = false"
          @onCancel="onCancel"
          @confirm="onConfirm"
        />
      </van-popup>
      <van-field name="您的学历" label="3.您的学历：">
        <template #input>
          <van-radio-group @change="change(2)" v-model="pageone[2].val" direction="horizontal">
            <van-radio name="专科及以下">专科及以下</van-radio>
            <van-radio name="本科">本科</van-radio>
            <van-radio name="研究生及以上">研究生及以上</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="您来自哪里" label="4.您来自哪里：">
        <template #input>
          <van-radio-group @change="change(3)" v-model="pageone[3].val" direction="horizontal">
            <van-radio name="城市">城市</van-radio>
            <van-radio name="乡镇">乡镇</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        name="近一个月内，你的填写心理问卷的频率是"
        label="5.近一个月内，你的填写心理问卷的频率是："
      >
        <template #input>
          <van-radio-group @change="change(4)" v-model="pageone[4].val" direction="horizontal">
            <van-radio name="0次">0次</van-radio>
            <van-radio name="1-3次">1-3次</van-radio>
            <van-radio name="3-5次">3-5次</van-radio>
            <van-radio name="5-10次">5-10次</van-radio>
            <van-radio name="10次以上">10次以上</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="你每天的手机使用时间" 
      label="6.你每天的手机使用时间：">
        <template #input>
          <van-radio-group @change="change(5)" v-model="pageone[5].val" direction="horizontal">
            <van-radio name="3小时以内">3小时以内</van-radio>
            <van-radio name="3-6小时">3-6小时</van-radio>
            <van-radio name="6-9小时">6-9小时</van-radio>
            <van-radio name="9-12小时">9-12小时</van-radio>
            <van-radio name="12小时以上"
              >12小时以上（例如上午9点玩到晚上9点以后）</van-radio
            >
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="你的左右手使用情况" 
      label="7.你的左右手使用情况：">
        <template #input>
          <van-radio-group @change="change(6)" v-model="pageone[6].val" direction="horizontal">
            <van-radio name="右利手">右利手</van-radio>
            <van-radio name="左利手">左利手（左撇子）</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit" @click="submit" v-preventReClick
          >下一页</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: "page_one",
  props: ["checkval"],
  data() {
    return {
      value:"",
      showPicker:false,
      columns:['18','19','20','21','22','23','24','25','26','27','28','29','30',
      '31','32','33','34','35','36','37','38','39','40'
    ],
      pageone:[
        {
          ID:"1",
          title:"1.你的性别：",
          val:"",
          starTime:"0",
          overTime:"0",
          time:"0"
        },
        {
          ID:"2",
          title:"2.年龄:",
          val:"",
          starTime:"0",
          overTime:"0",
          time:"0"
        },
        {
          ID:"3",
          title:"3.您的学历：",
          val:"",
          starTime:"0",
          overTime:"0",
          time:"0"
        },
        {
          ID:"4",
          title:"4.您来自哪里：",
          val:"",
          starTime:"0",
          overTime:"0",
          time:"0"
        },
        {
          ID:"5",
          title:"5.近一个月内，你的填写心理问卷的频率是：",
          val:"",
          starTime:"0",
          overTime:"0",
          time:"0"
        },
        {
          ID:"6",
          title:"6.你每天的手机使用时间：",
          val:"",
          starTime:"0",
          overTime:"0",
          time:"0"
        },
        {
          ID:"7",
          title:"7.你的左右手使用情况：",
          val:"",
          starTime:"0",
          overTime:"0",
          time:"0"
        },
      ]
    };
  },
  mounted() {
    console.log("111");
    window.scrollTo(0, 0)
    var scrollableElement = document.getElementsByClassName('check')[0];
    scrollableElement.scrollTop = 0;
  },

  methods: {
    onFailed() {},
    submit() {
      console.log(this.pageone,'this.pageone')
      let objVal= {
        arr:this.pageone,
        name:"pageone"
      }
      this.$emit("next", objVal);
    },
    change(index,item){
      console.log(index,item,'----=====')
      // 设置答题选择时间
      this.pageone[index].overTime = this.$moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss.SSS")
    },
    // 年龄
    onConfirm(value) {
      console.log(value,'val===年纪')
      // this.value = value;
      this.pageone[1].val = value
      this.pageone[1].overTime = this.$moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss.SSS")
      this.showPicker = false;
    },
    
    onCancel() {
       console.log('取消');
    },
  },
};
</script>

<style scoped lang="scss">
.page_one {
  /deep/.van-radio-group {
    display: flex;
    flex-direction: column;
    .van-radio {
      margin-top: 20px;
      margin-left: 20px;
    }
  }

  /deep/.van-cell {
      display: flex;
      flex-direction: column;
    }

    /deep/.age .van-cell__value {
      border: 1px solid gainsboro;
      margin-top: 10px;
      width: 250px;
      margin-left: 20px;
      .van-field__control {
        height: 35px;
      }
    }
    /deep/.van-button--block {
      width: 200px;
      margin: 0 auto;
    }
  h2 {
    color: #0095ff;
    margin-top: 50px;
  }
  .redstyle {
    color: red;
  }
  p {
    display: inline-block;
    text-align: justify;
    font-size: 16px;
    color: #262626;
    margin-top: 20px;
    line-height: 25px;
    padding-bottom: 30px;
    border-bottom: 1px dashed #ccc;
  }
}
</style>
