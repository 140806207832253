<template>
 <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    
  </div>
</template>

<script>
export default {
  name:"App"
}

</script>
<style scoped lang="scss" >
#app{
  width:100vw;
  height: 100vh;
}

</style>
