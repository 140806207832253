<template>
  <div class="page_four">
    <p>
      您好，下面是本研究的最后一部分。其实，上面的问题并不是本研究的主要目的。
      本研究主要考察手机上填写的电子问卷，从答题者的角度而言，是否存在不足之处。
      请根据你刚才的作答经历，对下面的问题认真作答。
      您的真实反馈将有助于我们后续对电子问卷的设计。
    </p>
    <van-form validate-first @failed="onFailed">
      <van-field v-for="(item, index) in list" :key="index" :label="item.title">
        <template #input>
          <van-radio-group @change="change(index)" v-model="list[index]['val']">
            <van-radio name="非常不符合"> 非常不符合 </van-radio>

            <van-radio name="不符合"> 不符合 </van-radio>

            <van-radio name="符合"> 符合 </van-radio>

            <van-radio name="非常符合"> 非常符合 </van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <div style="margin: 16px">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          @click="submit"
          v-preventReClick
          :disabled="disable"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: "page_four",
  props: ["checkval", "disable"],
  data() {
    return {
      list: [
        {
          ID: "87",
          title:
            "12.填写问卷时，如果有些题目很相似，我便不会仔细阅读每一道题的表述",
          model: "my1",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          ID: "88",
          title: "13.填写问卷时，我有加速滑动屏幕，快速选择答案的倾向",
          model: "my2",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          ID: "89",
          title: "14.答题过程中感到烦躁",
          model: "my3",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          ID: "90",
          title: "15.面对很多问题，我有连续几题快速选择同一选项的心理倾向",
          model: "my4",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
        {
          ID: "91",
          title:
            "16.我会故意调整某些选项，避免规律性作答，从而表现出“认真填写”的假象",
          model: "my5",
          val: "",
          starTime: "0",
          overTime: "0",
          time: "0",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0)
    var scrollableElement = document.getElementsByClassName('check')[0];
    scrollableElement.scrollTop = 0;
    
  },
  methods: {
    onFailed() {},
    submit() {
      let objVal4 = {
        arr: this.list,
        name: "pagefour",
      };
      console.log(this.list, "this.list");
      this.$emit("next", objVal4);
    },
    // 设置答题选择时间
    change(index) {
      console.log("--4-=====");
      this.list[index].overTime = this.$moment(new Date().getTime()).format(
        "YYYY-MM-DD HH:mm:ss.SSS"
      );
    },
    resizeScreen(val) {
      const _this = this;
      // 利用 CSS3 旋转 对根容器逆时针旋转 90 度
      const detectOrient = function () {
        let width = document.getElementsByClassName('van-dialog').offsetWidth,
          height = document.getElementsByClassName('van-dialog').offsetHeight,
          $wrapper = _this.$refs.view, // 这里是页面最外层元素
          style = "";
        if(val){
            // 横屏
            console.log("横屏1");
          style += "width:" + height + "px;";
          style += "height:" + width + "px;";
          style +=
            "-webkit-transform: rotate(90deg); transform: rotate(90deg);";
          // 注意旋转中点的处理
          style +=
            "-webkit-transform-origin: " +
            width / 2 +
            "px " +
            width / 2 +
            "px;";
          style += "transform-origin: " + width / 2 + "px " + width / 2 + "px;";
        }
        $wrapper.style.cssText = style;
      };
      window.onresize = detectOrient;
      detectOrient();
    },
  },
};
</script>

<style scoped lang="scss">
.page_four {
  p {
    color: #2e2e2e;
    font-size: 14px;
    text-align: justify;
    line-height: 25px;
  }
  /deep/.van-button {
    width: 200px;
    margin: 0 auto;
    text-align: center;
  }
}
</style>
