import pageone from './pageone.vue'
import pagetwo from './pagetwo.vue'
import pagethree from './pagethree.vue'
import pagefour from './pagefour.vue'

import pageoneE from './pageoneE.vue'
import pagetwoE from './pagetwoE.vue'
import pagethreeE from './pagethreeE.vue'
import pagefourE from './pagefourE.vue'

const GlobalCompoments = {
  install( Vue ) {
    Vue.component('pageone',pageone)
    Vue.component('pagetwo',pagetwo)
    Vue.component('pagethree',pagethree)
    Vue.component('pagefour',pagefour)
    Vue.component('pageoneE',pageoneE)
    Vue.component('pagetwoE',pagetwoE)
    Vue.component('pagethreeE',pagethreeE)
    Vue.component('pagefourE',pagefourE)
  }
}
export default GlobalCompoments