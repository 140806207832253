export default {
  // 当前时间
  getTime() {
    var date = new Date(),
      year = date.getFullYear(),
      month = date.getMonth() + 1,
      day = date.getDate(),
      hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
      minute =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
      second =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    month >= 1 && month <= 9 ? (month = "0" + month) : "";
    day >= 0 && day <= 9 ? (day = "0" + day) : "";
    var timer =
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
    return timer;
  },

  // 将时间戳相减获得差值
  calc_day(serverTime, now) {
    //如果是yymmdd格式的需要先new Date()处理
    // 服务时间
    var timestamp1 = new Date(serverTime).getTime();

    var timestamp0 = new Date(now).getTime();
    // 将时间戳相减获得差值（毫秒数）
    var differ = timestamp0 - timestamp1;

    /**
     * @desc 毫秒数除以1000就转为秒数
     * @desc 秒数除以60后取整，就是分钟（因为1分钟等于60秒）
     * @desc 秒数除以3600后取整，就是小时（因为1小时等于3600秒）
     * @desc 小时数除以24后取整，就是相差的天数
     */
    var val = differ / 1000 / 60;

    return parseInt(val);
  },
  // 时间日期转换，将秒数转换成02:20:22形式
  diffTime(val) {
    if (val < 60) {
      return val + "秒";
    } else {
      var min_total = Math.floor(val / 60); // 分钟
      var sec =
        Math.floor(val % 60) > 9
          ? Math.floor(val % 60)
          : "0" + Math.floor(val % 60); // 余秒
      if (min_total < 60) {
        return "00:" + min_total + ":" + sec;
      } else {
        var hour_total =
          Math.floor(min_total / 60) > 9
            ? Math.floor(min_total / 60)
            : "0" + Math.floor(min_total / 60); // 小时数
        var min =
          Math.floor(min_total % 60) > 9
            ? Math.floor(min_total % 60)
            : "0" + Math.floor(min_total % 60); // 余分钟
        console.log(hour_total + ":" + min + ":" + sec, "---xxx");
        return hour_total + ":" + min + ":" + sec;
      }
    }
  },
};
